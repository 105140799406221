// NumberCounter.jsx

import React, { useState, useEffect } from 'react';

const NumberCounter = ({ start, end, delay, prefix }) => {
  const [count, setCount] = useState(start);

  useEffect(() => {
    const interval = setInterval(() => {
      if (count < end) {
        setCount((prevCount) => prevCount + 1);
      } else {
        clearInterval(interval);
      }
    }, delay);
    
    return () => clearInterval(interval);
  }, [count, end, delay]);

  return (
    <>{prefix} {count}</>
  );
};

export default NumberCounter;
