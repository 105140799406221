import image1 from "../assets/WhatsApp Image 2024-03-03 at 10.41.21 PM.jpeg";
import image2 from "../assets/WhatsApp Image 2024-03-03 at 10.42.30 PM.jpeg";
import image3 from "../assets/WhatsApp Image 2024-03-03 at 10.43.57 PM.jpeg";
import image4 from "../assets/WhatsApp Image 2024-03-03 at 10.45.13 PM.jpeg";

export const testimonialsData = [
  {
    image: image1,
    review:
    "I made my transformation by choosen Pathakfitness and by choosing the right plan and program I already achieved my ideal body!",
    name: 'SURAJ BHATT',
    status : 'SOFTWARE ENGINEER'
  },
  {
    image: image2,
    review:'I have taken many gym membership and paid programs but i am unable to fulfiil my desires but after enrolling as a member of Pathakfitness , i am pushing myself daily',
    name: 'SAQIB KHAN',
    status: "BODYBUILDER"
  },
  {
    image : image3,
    review:' I usually do not want to study but after enrolling on this fitness plan, i am always energized and my productivity increases',
    name: 'LUV',
    status: "STUDENT"
  },
  {
    image : image4,
    review: 'I genuinely got results and my game also improves by Pathakfitness premium plans ',
    name: 'VISHAL RAJPUT',
    status: 'CRICKETER'
  }
];
