import './Reasons.css';
import React from 'react'
import image1 from "../../assets/sanpic/WhatsApp Image 2024-03-03 at 11.31.46 PM.jpeg";
import image2 from "../../assets/sanpic/WhatsApp Image 2024-03-03 at 11.31.00 PM.jpeg";
import image3 from "../../assets/sanpic/WhatsApp Image 2024-03-03 at 11.32.05 PM.jpeg";
import image4 from "../../assets/image3.png";
import nb from "../../assets/nb.png";
import addidas from "../../assets/adidas.png";
import nike from "../../assets/nike.png";
import tick from "../../assets/tick.png";


const Reasons = () => {
  return (
<div className="Reasons" id="reasons">
<div className="left-r">
    <img src={image1} alt="" />
    <img src={image2} alt="" />
    <img src={image3} alt="" />
    <img src={image4} alt="" />

</div>
<div className="right-r">
    <span>some reasons</span>
    <div>
        <span className='stroke-text'>why</span>
        <span> choose us?</span>
    </div>
    {/* trick to do this: div(div>img*span)*4 */}
     <div className='details-r'>
     <div>
        <img src={tick} alt=""></img>
        <span> OVER 140+ EXPERT COACHS</span>
     </div>
     <div>
        <img src={tick} alt="" />
        <span> TRAIN SMARTER AND FASTER THAN BEFORE </span>
     </div>
     <div>
        <img src={tick} alt="" />
        <span> 1 FREE PROGRAM FOR NEW MEMBER </span>
     </div>
     <div>
        <img src={tick} alt="" />
        <span> RELIABLE PARTNERS</span>
        </div>
 </div>
   <span style={{ color:"var(--gray)",
   fontWeight:'normal'
    }}>OUR PARTNERS</span>
   
   <div className="partners">
      <img src={nb} alt="" />
      <img src={addidas} alt="" />
      <img src={nike} alt="" />
      </div>

</div>

</div>

 )
}

export default Reasons