import React, { useRef } from 'react'
import './Join.css'
import emailjs from '@emailjs/browser';
const Join = () => {
  const mobile = window.innerWidth<=768 ? true: false;

  const form = useRef();
 
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_2w3wogg','template_e3n57p4', form.current,
       'jitqNZhnI-Umgn85E')
      .then(
        (result) => {
           console.log(result.text);
        },
        (error) => {
          console.log('FAILED...', error.text);
        }
      );};

  return (
 <div className="Join" id='join-us'>
<div className="left-j">
 <hr />
 <div> <span className='stroke-text'>READY TO</span>
  <span>LEVEL UP</span> </div>
  <div><span>YOUR BODY</span>
  <span className='stroke-text'>WITH US</span> </div>
</div>

<div className="right-j">
 <form ref={form}  className="email-container" onSubmit={sendEmail}>
    {mobile?
    <input type="email" name='user_email' placeholder='Enter your email' required />
   :
   <>
  <input type="email" name='user_email' placeholder='Enter your email' required />
  <input type="text" name='mobile' placeholder='Enter mobile number' required /> 
  </>  }
  <button className='btn btn-j'> Contact us</button>
 </form>
</div>

 </div>
    )
}

export default Join